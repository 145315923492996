import type { FetcherWithComponents } from "@remix-run/react";
import { withYup } from "@remix-validated-form/with-yup";
import { withZod } from "@remix-validated-form/with-zod";
import type { FormEvent, RefObject } from "react";
import * as React from "react";
import { useEffect, useMemo, useRef } from "react";
import { ValidatedForm, useFormContext } from "remix-validated-form";
import { ZipHiddenInput } from "~zipdeal-ui/inputs/zip/ZipHiddenInput.tsx";

export type ValidatedOptions = {
	clientId?: string;
	encType?:
		| "application/x-www-form-urlencoded"
		| "multipart/form-data"
		| "text/plain"
		| undefined;
	onSubmit?:
		| ((data: any, event: FormEvent<HTMLFormElement>) => void | Promise<void>)
		| undefined;
	fetcher?: FetcherWithComponents<any> | undefined;
	action?: string;
	formKey?: string;
	method?: "POST" | "GET";
	replaceableSchema?: boolean;
};

export const useValidatedForm = (
	formId,
	schema,
	defaultValues = {},
	zodSchema = false,
	options: ValidatedOptions = {
		clientId: "",
		formKey: "form",
		replaceableSchema: false,
	},
) => {
	const validator = useMemo(() => {
		// console.log(`schema`, formId, schema);

		// console.log({ formId, zodSchema });

		if (zodSchema) return withZod(schema);
		return withYup(schema);
	}, [schema, zodSchema]);

	const { clientId, formKey, replaceableSchema, ...formOptions } = options;

	const validatorFormId = `${formId}_VALIDATOR`;

	const formRef = useRef<HTMLFormElement>();

	const form = useFormContext(formId);

	const {
		defaultValues: validatorFormContextDefaultValues,
		validate: validateValidatorForm,
		reset: resetValidatorForm,
		isValid: isValidValidatorForm,
	} = useFormContext(validatorFormId);

	const { defaultValues: formContextDefaultValues, reset: resetForm } = form;

	useEffect(() => {
		if (validatorFormContextDefaultValues) {
			validateValidatorForm().then((r) => {});
		}
	}, [validatorFormContextDefaultValues, validateValidatorForm]);

	const validatorFormInitialized = useMemo(() => {
		return !!validatorFormContextDefaultValues;
	}, [validatorFormContextDefaultValues]);

	const formInitialized = useMemo(() => {
		return !!formContextDefaultValues;
	}, [formContextDefaultValues]);

	useEffect(() => {
		// Revalidate the validator if the validator changes (this might be because of a schema swap)
		if (replaceableSchema && validator && validatorFormInitialized) {
			validateValidatorForm();
		}
	}, [
		validator,
		validateValidatorForm,
		replaceableSchema,
		validatorFormInitialized,
	]);

	const validatedFormNode = useMemo(() => {
		return (
			<>
				<ValidatedForm
					key={`${formKey || ""}_${formId}_NORMAL`}
					id={formId}
					validator={validator}
					className={""}
					method={"POST"}
					autoComplete={"off"}
					role={"presentation"}
					defaultValues={defaultValues}
					{...formOptions}
					formRef={formRef as RefObject<HTMLFormElement>}
				/>
				<ValidatedForm
					key={`${formKey || ""}_${formId}_VALIDATOR`}
					id={validatorFormId}
					validator={validator}
					className={""}
					method={"POST"}
					autoComplete={"off"}
					role={"presentation"}
					defaultValues={defaultValues}
					{...formOptions}
				/>
				{clientId && (
					<>
						<ZipHiddenInput
							name={"CLIENT_ID"}
							formId={formId}
							value={clientId}
						/>
					</>
				)}
			</>
		);
	}, [
		clientId,
		defaultValues,
		formId,
		formKey,
		formOptions,
		validator,
		validatorFormId,
	]);

	return useMemo(() => {
		return {
			formId,
			validatorFormId,
			validatedFormNode,
			validator,
			formInitialized,
			validatorFormInitialized,
			formRef,
			isValidValidatorForm,
		};
	}, [
		formId,
		formInitialized,
		validatedFormNode,
		validator,
		validatorFormId,
		validatorFormInitialized,
		formRef,
		isValidValidatorForm,
	]);
};
